<ion-page>
  <app-header
    :title="projectData && projectData.name"
    :isDetail="true"
    :defaultHref="'projects'"
  ></app-header>
  <ion-content :fullscreen="true">
    <ion-grid>
      <ion-row>
        <ion-col size="3" size-md="1" offset="9" offset-md="11">
          <ion-buttons v-if="isAuthenticated">
            <ion-button size="small" @click="editProject">
              <ion-icon :icon="pencil" slot="icon-only"></ion-icon>
            </ion-button>
            <ion-button size="small" @click="deleteProject">
              <ion-icon :icon="trash" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size-md="6">
          <app-slides
            v-if="slidesData.length"
            :cardData="cardData"
            :data="slidesData"
            :withActions="false"
            :withDetail="false"
          ></app-slides>
        </ion-col>
        <ion-col size="12" size-md="5">
          <ion-card class="ion-padding-bottom" v-if="projectData">
            <ion-card-header>
              <ion-card-title>
                Project Specs
              </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-row>
                <ion-col size="6" size-md="12" class="ion-no-padding">
                  <ion-item lines="none">
                    <ion-label position="stacked">Languages:</ion-label>
                    <ion-text>
                      {{ prettyPrint(projectData.languages) }}
                    </ion-text>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label position="stacked">Frameworks:</ion-label>
                    <ion-text>
                      {{ prettyPrint(projectData.frameworks) }}
                    </ion-text>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label position="stacked">Databases:</ion-label>
                    <ion-text>
                      {{ prettyPrint(projectData.databases) }}
                    </ion-text>
                  </ion-item>
                </ion-col>
                <ion-col size="6" size-md="12" class="ion-no-padding">
                  <ion-item lines="none">
                    <ion-label position="stacked">Cloud Providers:</ion-label>
                    <ion-text>
                      {{ prettyPrint(projectData.cloudProviders) }}
                    </ion-text>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label position="stacked">Date Completed:</ion-label>
                    <ion-text>
                      {{ projectData.dateCompleted }}
                    </ion-text>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="10" offset="1">
          <ion-text>{{ projectData && projectData.description }}</ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ion-page>
